'use client';

import FullPageError from './components/Error/FullPageError';
import { useLogException } from './hooks/useLogException';

const errorSwap = [
  {
    title: 'invalid_request',
    message: 'invitation not found or already used',
    swapTitle: 'Invalid invitation',
    swapMessage:
      'The invitation link you are trying to use is invalid. It might have expired or already been used.',
  },
];

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useLogException(error);

  let errorTitle = error.name;
  let errorMessage = error.message;

  const swapped = errorSwap.find(
    (swap) => swap.title === error.name && swap.message === error.message,
  );

  if (swapped) {
    errorTitle = swapped.swapTitle;
    errorMessage = swapped.swapMessage;
  }
  return (
    <html>
      <body>
        <FullPageError title={errorTitle} message={errorMessage} />
      </body>
    </html>
  );
}
