import type { MeasuredCarbonverse } from '../../schemas/carbonverse/types';
import type { AccountEndpoints } from './account/endpoints';
import type { AuthEndPoints } from './auth/endpoints';
import type { BrandEndpoints } from './brands/endpoints';
import type { ChangelogEndpoints } from './changelog/endpoints';
import type { CompanyModelingEndpoints } from './company-modeling/endpoints';
import type { CorporateEndpoints } from './corporate/endpoints';
import type { DppEndpoints } from './dpp/endpoints';
import type { FactoriesEndpoints } from './factories/endpoints';
import type { MaterialsEndpoints } from './materials/endpoints';
import type { MetadataEndpoints } from './metadata/endpoints';
import type { MetricsCubeEndpoints } from './metrics-cube/endpoints';
import type { ProductModelingEndpoints } from './product-modeling/endpoints';
import type { ProductEndpoints } from './products/endpoints';
import type { ReleasesEndpoints } from './releases/endpoints';
import type { ReportsEndpoints } from './reports/endpoints';
import type { RulesEndpoints } from './rules/endpoints';
import type { SettingsEndpoints } from './settings/endpoints';
import type { TranslationsEndpoint } from './translations/endpoint';
import type { UserEndpoints } from './user/endpoint';

export type MCV = MeasuredCarbonverse;
export type MCVMaterial =
  MeasuredCarbonverse['components'][number]['materials'][number];
export type MCVMaterialProcessStep = MCVMaterial['process_steps'][number];
export type MCVComponentProcessStep =
  MeasuredCarbonverse['components'][number]['process_steps'][number];
export type MCVProcessStep = MeasuredCarbonverse['process_steps'][number];
export type MCVComponent = MeasuredCarbonverse['components'][number];
export type MCVDistribution = MeasuredCarbonverse['distribution'];
export type MCVDistributionTransportScenario =
  MeasuredCarbonverse['distribution']['transport_scenarios'][number];
export type MaterialConfig =
  PlatformApiEndpoints['/product-modeling/config']['response']['materials'][number];
export type MCVPackagingComponent =
  MeasuredCarbonverse['distribution']['packaging']['components'][number];

export type MCVComponentWithLossRate = MCVComponent & {
  overallLossPercentage: number | null;
};

export type DropdownFilter = {
  key: string;
  values: string[];
};

export type FootprintCategory =
  | 'rawMaterials'
  | 'processing'
  | 'assembling'
  | 'distribution'
  | 'use'
  | 'endOfLife';

export interface PaginationParams {
  page: number;
  limit: number;
}

export interface PaginatedResponse<T> {
  page: T[];
  pageNumber: number;
  totalPageCount: number;
  totalRowCount: number;
}

export type PlatformApiEndpoints = AccountEndpoints &
  AuthEndPoints &
  BrandEndpoints &
  ChangelogEndpoints &
  CompanyModelingEndpoints &
  DppEndpoints &
  FactoriesEndpoints &
  MaterialsEndpoints &
  MetadataEndpoints &
  MetricsCubeEndpoints &
  CorporateEndpoints &
  ProductEndpoints &
  ProductModelingEndpoints &
  ReleasesEndpoints &
  ReportsEndpoints &
  SettingsEndpoints &
  TranslationsEndpoint &
  RulesEndpoints &
  UserEndpoints;

export enum UserRole {
  ADMIN = 'CARBONFACT/ADMIN',
  USER = 'ACCOUNT/USER',
}

export * from './user-editable-data';
