import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

export const useLogException = (error: Error) => {
  useEffect(() => {
    // Global errors will always go through this path and have the same stack trace.
    // Make sure to group them by what the error actually is, rather than the stack trace.
    Sentry.withScope((scope) => {
      // Docs:
      // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting
      scope.setFingerprint([error.name, error.message]);

      Sentry.captureException(`${error.name}: ${error.message}`);
    });
  }, [error]);
};
