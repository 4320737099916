'use client';
import Icon from '@carbonfact/ui-components/src/Icon';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { useState } from 'react';

export interface DefaultProps {
  content: string;
  children?: React.ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom';
  gap?: number;
  trigger?: 'click' | 'hover';
}
export function Default({
  content,
  children,
  position = 'left',
  gap = 5,
  trigger = 'click',
}: DefaultProps) {
  const [hovered, setHovered] = useState(false);
  const handleOverIn = () => {
    if (trigger === 'hover') {
      setHovered(true);
    }
  };
  const handleOverOut = () => {
    if (trigger === 'hover') {
      setHovered(false);
    }
  };
  return (
    <Popover
      className="relative"
      onMouseEnter={handleOverIn}
      onMouseLeave={handleOverOut}
    >
      {({ open }) => (
        <>
          <PopoverButton as="div" className="focus:outline-hidden cursor-auto">
            <div
              className={classNames(
                'flex flex-row gap-1 transition-opacity duration-200',
                trigger !== 'hover' && 'cursor-pointer',
              )}
            >
              {trigger !== 'hover' && (
                <Icon
                  height={20}
                  width={20}
                  icon={{
                    source: 'local',
                    name: 'info-cr-fr',
                    type: 'solid',
                  }}
                  className=""
                />
              )}
              {children}
            </div>
          </PopoverButton>
          {((trigger === 'hover' && hovered) ||
            (trigger !== 'hover' && open)) && (
            <PopoverPanel
              static
              anchor={{
                to: position,
                gap,
              }}
              className={classNames(
                `z-50 bg-white ${top} rounded-sm shadow-md border border-gray-200 overflow-hidden text-center`,
              )}
            >
              <div className="text-xs bg-carbon-800 text-white py-2 px-4 max-w-[250px]">
                {content}
              </div>
            </PopoverPanel>
          )}
        </>
      )}
    </Popover>
  );
}
