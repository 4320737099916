'use client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {
  type PlatformApiEndpoints,
  UserRole,
} from '@carbonfact/shared/src/types/platform';
import * as Sentry from '@sentry/nextjs';
import GlobalError from 'app/global-error';
import { Analytics } from 'app/lib/analytics';
import { API_BASE_URL } from 'configuration/constants';
import { useSearchParams } from 'next/navigation';
import { type ReactNode, useEffect, useMemo } from 'react';
import useSWR from 'swr';

export type RawUser = PlatformApiEndpoints['/auth/me']['response'];

export type User = RawUser & {
  // Derived
  isAdmin: boolean;
};

export function useCurrentUser(): User | undefined {
  const { data: rawUser } = useSWR('/auth/me');

  const user = useMemo(() => {
    if (!rawUser) return undefined;
    return enrichUser(rawUser);
  }, [rawUser]);

  return user;
}

async function fetchCurrentUser(token: string): Promise<User | null> {
  // we don't use SWR here because this is used above the SWRContext depth
  const response = await fetch(`${API_BASE_URL}/auth/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status === 401 || response.status === 429) {
    return null;
  }

  const data =
    (await response.json()) as PlatformApiEndpoints['/auth/me']['response'];

  if (!response.ok) {
    throw new Error(
      `Error ${response.status}: ${(data as unknown as { message: string }).message}`,
    );
  }

  return enrichUser(data);
}

function enrichUser(user: RawUser): User {
  return {
    ...user,
    isAdmin: user.role === UserRole.ADMIN,
  };
}

let previousUserEmail: string | null = null;
function MonitoringUserTracker() {
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    function unsetMonitoringUser() {
      Analytics.userReset();
      Sentry.setUser(null);
    }

    void (async () => {
      if (!user) {
        unsetMonitoringUser();
        previousUserEmail = null;
        return;
      }

      if (previousUserEmail === user.email) {
        // no need to refresh user info
        console.log('user email did not change');
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently();
        const currentUser = await fetchCurrentUser(accessToken);
        const isCarbonfactUser = currentUser?.email.endsWith('@carbonfact.com');

        if (!currentUser) {
          unsetMonitoringUser();
          return;
        }

        Analytics.userIdentify(currentUser.id.toString(), {
          email: isCarbonfactUser
            ? currentUser.email // Do not anonymize Carbonfact users
            : currentUser.email.replace(/^.+@/, 'anonymized@'),
          brand: currentUser.accountSlug, // keeping brand here to maintain identified users on PH
        });
        Sentry.setTag('account', currentUser.accountSlug);
        Sentry.setTag(
          'internal-user',
          currentUser.email.endsWith('@carbonfact.com'),
        );
        Sentry.setUser({
          email: isCarbonfactUser
            ? currentUser.email // Do not anonymize Carbonfact users
            : currentUser.email.replace(/^.+@/, 'anonymized@'),
          account_slug: currentUser.accountSlug, // keeping account_slug here to maintain identified users on Sentry
        });
        window._hsq = window._hsq || [];
        const _hsq = window._hsq;
        _hsq.push([
          'identify',
          {
            email: user.email,
          },
        ]);

        previousUserEmail = currentUser.email;
      } catch (error) {
        console.error('Error while fetching user info', error);
      }
    })();
  }, [user, getAccessTokenSilently]);

  return null;
}

function Auth0CallbackHandler({ children }: { children: ReactNode }) {
  const searchParams = useSearchParams();

  // Auth0 might return authentication errors through search params.
  // Handle those here
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  if (typeof error === 'string') {
    return (
      <GlobalError
        error={{
          name: error,
          message: errorDescription || 'Unknown error',
        }}
      />
    );
  }
  return <>{children}</>;
}

export default function AuthProvider({ children }: { children?: ReactNode }) {
  if (
    !process.env.NEXT_PUBLIC_AUTH0_DOMAIN ||
    !process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
  ) {
    throw new Error('Missing Auth0 environment variables');
  }

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri:
          typeof window !== 'undefined'
            ? window.location.origin
            : 'https://platform.carbonfact.com',
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      }}
      onRedirectCallback={(appState) => {
        // Try to get back to the last page after re-auth
        if (appState?.returnTo) {
          console.info(
            'Redirecting to oauth returnTo state URL:',
            appState.returnTo,
          );
          window.open(appState.returnTo, '_self');
        } else {
          // Just clean up oauth state from the URL otherwise
          window.location.replace(window.location.pathname);
        }
      }}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <Auth0CallbackHandler>
        <MonitoringUserTracker />
        {children}
      </Auth0CallbackHandler>
    </Auth0Provider>
  );
}
